/**
 * @generated SignedSource<<4e3bb0b024261ee09fbd173fd5d721b3>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* eslint-disable */

'use strict';

var node = (function(){
var v0 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "state",
  "storageKey": null
};
return {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "OrderStatus_status",
  "selections": [
    (v0/*: any*/),
    {
      "alias": null,
      "args": null,
      "concreteType": "OrderHistory",
      "kind": "LinkedField",
      "name": "history",
      "plural": true,
      "selections": [
        (v0/*: any*/),
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "date",
          "storageKey": null
        }
      ],
      "storageKey": null
    }
  ],
  "type": "OrderStatus",
  "abstractKey": null
};
})();

node.hash = "a324ef97a7f1dbba462686b689e8e476";

module.exports = node;
