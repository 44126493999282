/**
 * @generated SignedSource<<e64078c440ca4acccdee4150ae3f24a7>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* eslint-disable */

'use strict';

var node = (function(){
var v0 = [
  {
    "args": null,
    "kind": "FragmentSpread",
    "name": "Address_address"
  }
];
return {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "OrderAddresses_addresses",
  "selections": [
    {
      "alias": null,
      "args": null,
      "concreteType": "Address",
      "kind": "LinkedField",
      "name": "shipping",
      "plural": false,
      "selections": (v0/*: any*/),
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "Address",
      "kind": "LinkedField",
      "name": "billing",
      "plural": false,
      "selections": (v0/*: any*/),
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "billingSameAsShipping",
      "storageKey": null
    }
  ],
  "type": "Addresses",
  "abstractKey": null
};
})();

node.hash = "9b5a5d78cf846606e07f0fdd73632006";

module.exports = node;
